import React from 'react';
import { node, string } from 'prop-types';
import cx from 'classnames';

import Table from 'react-bootstrap/Table';

const propTypes = {
  children: node.isRequired,
  className: string,
};

const defaultProps = {
  className: undefined,
};

const TableWrapper = ({ children, className, ...props }) => {
  const classes = cx('c-table', className);

  return (
    <Table className={classes} {...props} bordered>
      {children}
    </Table>
  );
};

TableWrapper.propTypes = propTypes;
TableWrapper.defaultProps = defaultProps;

export default TableWrapper;
