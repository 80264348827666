import React, { useEffect, useState, useRef } from 'react';
import { string, arrayOf } from 'prop-types';
import cx from 'classnames';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const propTypes = {
  links: arrayOf(string),
};

const defaultProps = {
  links: [],
};

const FloatingMenu = ({ links }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [activeLink, setActiveLink] = useState('');
  const collapseRef = useRef(null);
  const navbarClasses = cx('c-sticky-navbar z-10 p-0', {
    'is-sticky-top': isSticky,
  });
  const toggleClasses = cx(
    'c-sticky-navbar-nav d-flex flex-column align-items-lg-center justify-content-center py-4 py-lg-0 px-4 px-lg-2',
    {
      collapsed: !isCollapsed,
    },
  );

  const handleScroll = () => {
    const triggerElement = document.querySelector('.c-section__sticky');
    const triggerOffset = triggerElement ? triggerElement.offsetTop : 0;

    if (window.scrollY >= triggerOffset) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }

    links.forEach((link) => {
      const sectionId = link.toLowerCase().replace(/ /g, '-');
      const section = document.getElementById(sectionId);

      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 100 && rect.bottom >= 100) {
          setActiveLink(link);
        }
      }
    });
  };

  const handleMouseWheel = () => {
    const isTouchDevice =
      'ontouchstart' in window || navigator.maxTouchPoints > 0;

    if (isTouchDevice && collapseRef.current) {
      collapseRef.current.classList.remove('show');
      setIsCollapsed(false);
    }
  };

  const handleToggle = (collapsed) => {
    setIsCollapsed(collapsed);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('wheel', handleMouseWheel);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('wheel', handleMouseWheel);
    };
  });

  return (
    <Navbar expand="true" className={navbarClasses} onToggle={handleToggle}>
      <Container className="position-relative flex-column align-items-end">
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className={toggleClasses}
        >
          <div className="w-100 d-lg-none">
            <span className="c-icon-bar-text position-relative d-flex text-start w-100 font-size-sm text-dark text-uppercase">
              Table of Contents
            </span>
          </div>
          <span className="c-icon-bar d-none d-lg-block w-100" />
        </Navbar.Toggle>
        <Navbar.Collapse
          ref={collapseRef}
          id="sticky-navbar-nav"
          className="position-absolute shadow rounded-xxs bg-white z-10"
        >
          <Nav>
            <span className="fw-bold text-uppercase border-bottom p-3">
              Table of Contents
            </span>
            {links.map((link) => {
              const formattedLink = link.toLowerCase().replace(/ /g, '-');
              return (
                <Nav.Link
                  key={link}
                  href={`#${formattedLink}`}
                  className={`position-relative text-gray font-weight-medium border-bottom p-3 ${
                    activeLink === link ? 'active' : ''
                  }`}
                >
                  {link}
                </Nav.Link>
              );
            })}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

FloatingMenu.propTypes = propTypes;
FloatingMenu.defaultProps = defaultProps;

export default FloatingMenu;
