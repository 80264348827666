import React from 'react';
import { node } from 'prop-types';
import Button from 'react-bootstrap/Button';

const propTypes = {
  children: node.isRequired,
};

const IconButton = ({ children, ...props }) => {
  return (
    <Button className="c-icon-button" {...props}>
      {children}
    </Button>
  );
};

IconButton.propTypes = propTypes;

export default IconButton;
