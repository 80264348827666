import React, { useState, useRef, useEffect } from 'react';
import { node, string } from 'prop-types';
import cx from 'classnames';
import { FiChevronDown } from '@react-icons/all-files/fi/FiChevronDown';

const propTypes = {
  title: string.isRequired,
  children: node.isRequired,
  className: string,
};

const defaultProps = {
  className: undefined,
};

const TableDropdown = ({ title, children, className }) => {
  const classes = cx(
    'c-table-dropdown w-100 text-start border rounded-xs bg-transparent px-3 py-4',
    className,
  );

  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : '0px';
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };

  const arrowClasses = cx({ active });

  return (
    <button type="button" className={classes} onClick={toggleAccordion}>
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="fw-bold mb-0">{title}</h4>
        <FiChevronDown className={arrowClasses} />
      </div>
      <div
        ref={contentRef}
        className="c-table-dropdown-content overflow-hidden"
      >
        <div className="pt-2">{children}</div>
      </div>
    </button>
  );
};

TableDropdown.propTypes = propTypes;
TableDropdown.defaultProps = defaultProps;

export default TableDropdown;
