import React from 'react';
import { string, bool } from 'prop-types';
import cx from 'classnames';
import Col from 'react-bootstrap/Col';

const propTypes = {
  col: string.isRequired,
  title: string.isRequired,
  text: string.isRequired,
  icon: string,
  className: string,
  showIcon: bool,
};

const defaultProps = {
  icon: undefined,
  className: undefined,
  showIcon: false,
};

const SimpleCardItem = ({ col, title, text, icon, className, showIcon }) => {
  const colClasses = cx(className);
  const cardClasses = cx(
    'c-simple-card-item position-relative d-flex h-100 shadow rounded-xs',
    {
      'align-items-start gap-4 gap-lg-5 p-4 p-lg-5': showIcon,
      'flex-column justify-content-center p-3 has-border': !showIcon,
    },
  );

  return (
    <Col className={colClasses} lg={col}>
      <div className={cardClasses}>
        {showIcon && <img src={icon} alt="" />}
        <div>
          <h4 className="fw-bold mb-2">{title}</h4>
          <p className="text-gray-700 mb-0">{text}</p>
        </div>
      </div>
    </Col>
  );
};

SimpleCardItem.propTypes = propTypes;
SimpleCardItem.defaultProps = defaultProps;

export default SimpleCardItem;
