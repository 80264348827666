import React from 'react';
import { string, node } from 'prop-types';
import cx from 'classnames';

const propTypes = {
  title: string.isRequired,
  text: string.isRequired,
  children: node.isRequired,
  className: string,
};

const defaultProps = {
  className: undefined,
};

const TableDropdownItem = ({ title, text, children, className }) => {
  const classes = cx('c-table-dropdown-item border-bottom py-4', className);

  return (
    <div className={classes}>
      <p className="fw-bolder mb-2">{title}</p>
      <p className="font-size-xs fw-normal">{text}</p>
      <div className="d-grid c-table-dropdown-grid">
        <span className="fw-bolder text-white text-center bg-secondary py-3 px-1">
          Bridge
        </span>
        <span className="text-center bg-gray-200 py-3 px-1">InteliChart</span>
        <span className="text-center bg-gray-200 py-3 px-1">Phreesia</span>
        <span className="text-center bg-gray-200 py-3 px-1">Relatient</span>
      </div>
      {children}
    </div>
  );
};

TableDropdownItem.propTypes = propTypes;
TableDropdownItem.defaultProps = defaultProps;

export default TableDropdownItem;
