import React from 'react';
import { node, string } from 'prop-types';
import cx from 'classnames';

const propTypes = {
  children: node.isRequired,
  className: string,
};

const defaultProps = {
  className: undefined,
};

const IconList = ({ children, className, ...props }) => {
  const classes = cx('c-icon-list p-0', className);

  return (
    <ul className={classes} {...props}>
      {children}
    </ul>
  );
};

IconList.propTypes = propTypes;
IconList.defaultProps = defaultProps;

export default IconList;
