import React, { useState, useRef, useEffect } from 'react';
import { node, string } from 'prop-types';
import cx from 'classnames';
import { FiChevronDown } from '@react-icons/all-files/fi/FiChevronDown';

const propTypes = {
  children: node.isRequired,
  question: string.isRequired,
};

const FaqSingle = ({ children, question }) => {
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : '0px';
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };

  const classes = cx({ active });

  return (
    <button
      type="button"
      className="c-faq-item w-100 text-start border-0 bg-transparent px-4 py-4"
      onClick={toggleAccordion}
    >
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="fw-bold my-2">{question}</h4>
        <FiChevronDown className={classes} />
      </div>
      <div ref={contentRef} className="c-faq-answer overflow-hidden">
        <div className="pt-4 pb-3">{children}</div>
      </div>
    </button>
  );
};

FaqSingle.propTypes = propTypes;

export default FaqSingle;
