import React from 'react';
import { node } from 'prop-types';

const propTypes = {
  children: node.isRequired,
};

const TableHead = ({ children }) => {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
};

TableHead.propTypes = propTypes;

export default TableHead;
