import React from 'react';
import { string, number, bool } from 'prop-types';
import cx from 'classnames';

const propTypes = {
  title: string.isRequired,
  colSpan: number,
  titleTag: bool,
  className: string,
};

const defaultProps = {
  colSpan: 1,
  titleTag: false,
  className: undefined,
};

const TableHeadItem = ({ title, colSpan, titleTag, className }) => {
  const itemClass = cx('c-table-head-item py-4 px-3', className);

  return (
    <th className={itemClass} colSpan={colSpan}>
      {titleTag ? (
        <h4 className="fw-bolder mb-0">{title}</h4>
      ) : (
        <span className="d-block font-size-sm fw-bolder text-center align-content-center">
          {title}
        </span>
      )}
    </th>
  );
};

TableHeadItem.propTypes = propTypes;
TableHeadItem.defaultProps = defaultProps;

export default TableHeadItem;
