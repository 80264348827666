import React from 'react';
import { node } from 'prop-types';

const propTypes = {
  children: node.isRequired,
};

const TableBody = ({ children }) => {
  return <tbody className="border">{children}</tbody>;
};

TableBody.propTypes = propTypes;

export default TableBody;
