import React from 'react';
import { string, number, bool } from 'prop-types';
import cx from 'classnames';

import checkIcon from '../../images/check-icon.svg';
import crossIcon from '../../images/cross-icon.svg';

const propTypes = {
  title: string,
  text: string,
  colSpan: number,
  icon: bool,
  iconType: string,
  className: string,
};

const defaultProps = {
  title: undefined,
  text: undefined,
  colSpan: 1,
  icon: false,
  iconType: undefined,
  className: undefined,
};

const TableBodyItem = ({ title, text, colSpan, icon, iconType, className }) => {
  const itemClass = cx('c-table-body-item p-3', className);

  let selectedIcon = null;

  if (iconType === 'check') {
    selectedIcon = checkIcon;
  } else if (iconType === 'cross') {
    selectedIcon = crossIcon;
  }

  return (
    <td className={itemClass} colSpan={colSpan}>
      {icon && selectedIcon ? (
        <img src={selectedIcon} alt={iconType} />
      ) : (
        <>
          <p className="font-size-sm fw-bolder mb-1">{title}</p>
          <p className="fw-normal mb-0">{text}</p>
        </>
      )}
    </td>
  );
};

TableBodyItem.propTypes = propTypes;
TableBodyItem.defaultProps = defaultProps;

export default TableBodyItem;
