import React from 'react';
import { string } from 'prop-types';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FaArrowRight } from '@react-icons/all-files/fa/FaArrowRight';
import IconButton from '../IconButton/IconButton';

const propTypes = {
  title: string.isRequired,
  subTitle: string.isRequired,
  label: string.isRequired,
  uri: string.isRequired,
};

const HeroParticles = ({ title, subTitle, label, uri }) => {
  return (
    <section className="c-hero-particles position-relative overflow-hidden">
      <Container>
        <Row>
          <Col>
            <h1 className="text-center text-white mb-4">{title}</h1>
            <Row className="justify-content-center text-center">
              <Col lg={8}>
                <h3 className="text-white mb-0">{subTitle}</h3>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="c-hero-particles-curve position-relative d-flex justify-content-center">
        <IconButton className="c-icon-button__big position-absolute" href={uri}>
          {label} <FaArrowRight size={16} className="ms-2" />
        </IconButton>
      </div>
    </section>
  );
};

HeroParticles.propTypes = propTypes;

export default HeroParticles;
