import React from 'react';
import { node, string } from 'prop-types';
import cx from 'classnames';

const propTypes = {
  children: node.isRequired,
  className: string,
};

const defaultProps = {
  className: undefined,
};

const Faqs = ({ children, className }) => {
  const classes = cx('c-faqs border rounded-xs', className);

  return <div className={classes}>{children}</div>;
};

Faqs.propTypes = propTypes;
Faqs.defaultProps = defaultProps;

export default Faqs;
