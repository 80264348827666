import React from 'react';
import { node } from 'prop-types';

const propTypes = {
  children: node.isRequired,
};

const TableBodyRow = ({ children }) => {
  return <tr>{children}</tr>;
};

TableBodyRow.propTypes = propTypes;

export default TableBodyRow;
